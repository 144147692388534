@import "style-guide";

.wrap {
  padding-bottom: 5*$u;
}

.text {
  padding-right: $u;

  @include field-label;
}

.field-label {
  min-height: 4*$u;
  margin-bottom: $u;
}

.field-value {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2*$u;
  min-height: 8*$u;

  &--withInputLayout {
    padding: 2*$u 3*$u;
    border-radius: $controlDefaultBorderRadius;
    background-color: $black-10-percent;
  }

  &__icon {
    flex-shrink: 1;
  }

  &__label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 4*$u;
    flex-grow: 1;

    @include Input-Value-Placeholder-font;

    &--hasValue {
      @include Input-Value-Bold-font;
    }
  }

  &__right-node {
    flex-shrink: 1;
  }
}

.tooltip {
  display: inline-block;

  &__icon {
    vertical-align: top;
  }
}

.unit {
  margin-left: $u;
}
