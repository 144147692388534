@import "style-guide";

.option {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 2*$u;

  &--isSelected {
    background-color: $light-30-percent;
  }

  &--withoutPaddings {
    padding: 0;
  }

  &__icon {
    min-width: 4*$u;
    height: 4*$u;
    margin-right: 2*$u;

    &--isDisabled {
      opacity: 0.5;
    }
  }

  &__label {
    margin-right: 2*$u;
    white-space: nowrap;

    @include Input-Value-Normal-font;

    &--isDisabled {
      font-style: italic;
      opacity: 0.5;
    }

    &--isEmpty {
      @include Input-Value-Placeholder-font;
    }
  }

  &__spacer {
    flex-grow: 1;
  }

  &__description {
    @include Input-Description-Normal-font;
  }
}
