@import "style-guide";

.content {
  display: flex;
  align-items: center;
  min-height: 8*$u;
}

.status-wrap {
  margin-right: 8*$u;
}

.actions {
  flex-grow: 1;
  display: flex;
}
