@import "style-guide";

.alerts {
  // fix empty alerts
  & > div:last-child {
    margin-bottom: 6*$u;
  }
}

.button {
  width: 100%;
}

.block {
  margin-bottom: 3*$u;

  &__title {
    @include Title-Widget-font;

    margin-bottom: 6*$u;
  }
}

.icon {
  margin-bottom: 6*$u;
}

.fields {
  margin-bottom: 3*$u;
}

.field {
  &--country {}

  &--plate-number,
  &--rate,
  &--vin,
  &--contract {
    width: 50%;
  }
}
