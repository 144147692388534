@mixin table-column-auto-adjust-width {
  width: 1px;
  white-space: nowrap;
}

@mixin bold-font-for-avoid-jumping($fontColor) {
  text-shadow: 0.5px 0 0 $fontColor;
}

@mixin clear-bold-font-for-avoid-jumping {
  text-shadow: none;
}
